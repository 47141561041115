.application-title {
  flex-direction: column;
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin-bottom: 0;
  }
}

.application__submit {
  margin-top: 1rem;
}

.application__form-buttons {
  padding-top: 2rem;
}

.application__collaborator {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;

  .application__collaborator-name {
    font-weight: bold;
  }
}

.application__form fieldset {
  margin: 0 -2rem 2rem -2rem;
  padding: 2rem 1rem 2rem 1rem;

  @include media-breakpoint-up(lg) {
    margin: 0 -2rem 2rem -2rem;
    padding: 2rem 2rem 2rem 2rem;
    border: 1px solid #ababab;
  }

  position: relative;
  background: #fff;
}

.panel-inner-block {
  flex: 1;
}

.content .application--list {
  margin-top: 0;
}

.was-validated :invalid +.invalid-feedback,
.was-validated :invalid +.invalid-tooltip,
.is-invalid + .invalid-feedback,
.is-invalid + .invalid-tooltip {
  display: block;
}

.error-hint-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  width: 1rem;
  height: 1rem;
  background-size: 1rem;
  background-repeat: no-repeat;
  display: inline-block;
}

.application__form {

  .required {
    .asteriskField {
      display: none;
    }

    label:not(.custom-control-label):not(.custom-file-label):after {
      font-weight: bold;
      content: "*";
    }

    label.custom-control-label>span:after {
      content: " *";
    }
  }

  &.active-form .required {

    $field-required: rgb(225, 175, 46);

    input[type="text"],
    input[type="email"],
    textarea,
    select {
      border-color: $field-required;

      &:focus {
        box-shadow: 0 0 0 0.2rem transparentize(darken($field-required, 5%), 0.75);
      }
    }

    .custom-file-label,
    .custom-file-current-file {
      border-color: $field-required;

      &:focus {
        box-shadow: 0 0 0 0.2rem transparentize(darken($field-required, 5%), 0.75);
      }
    }

    .custom-control-input:not(:checked)~.custom-control-label:not(.custom-file-delete-label)::before {
      border-color: $field-required;

      &:focus {
        box-shadow: 0 0 0 0.2rem transparentize(darken($field-required, 5%), 0.75);
      }
    }

  }

  label {
    font-size: 1.0em;
    margin-bottom: 0.2rem;
  }

  label:not(.custom-control-label) {
    font-weight: bold;
  }

  legend {
    line-height: 1.25;
    text-transform: uppercase;
    padding-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      padding-bottom: 2rem;
    }
  }

  .form-text.text-muted {
    margin-top: 0;
    margin-bottom: 0.1rem;
    font-size: 0.95em;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }


  .custom-file-label::after {
    content: "Durchsuchen"
  }

  .form-control.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

   .form-control.is-invalid {
     border-color: #dc3545;
   }

  .application__questionnaire .form-group {
    margin-bottom: 2rem;
  }

  .submit-hint {
    margin-top: 0.5rem;
    line-height: 1.15;
  }

  .label.is-required::after {
    content: '*';
    padding-left: 0.1rem;
  }

  .file-field {
    flex-direction: column;
    display: inline-flex;
    flex-wrap: nowrap;
    word-break: break-all;

  }

  .file-container {
    position: relative;
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
  }

  .file--filled {

    .file-field {
      margin-right: 1rem;
      width: 50%;
    }

    .file-input {
      left: 50%;
    }
  }

  .file--filled,
  .file--empty {
    .help {
      font-size: 1rem;
    }
  }


  .collapse-question {
    margin-top: 1.5rem;
    margin-bottom: .5rem;
  }

  .collapse-field {
    label {
      font-size: .9em;
      margin-bottom: 0.1rem;
      font-weight: normal;
    }
  }
}

.application--accept {
  margin-bottom: 1rem;

  strong {
    vertical-align: top;
    display: none;
  }
}

.application--hint {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.help.is-before-input {
  margin-top: -0.5rem;
  font-size: 1rem;
}

.content p.help.is-before-input{
  margin-bottom: 0.5rem;
}

.upload-hint {
  color: $gray-600;
}

.form-row {
  align-items: flex-end;
}

.container-fluid.main {
  margin-top: 5rem;
}

.container.main {
  margin-bottom: 2rem;
}

.application-accept {
  display: flex;
  margin-top: -1.2rem;
  margin-bottom: 1rem;

  .btn {
    margin-left: auto;
  }
}


.save-draft-button,
.print-application-button {
  @include media-breakpoint-down(md) {
    &.btn-sm {
      padding: 0.15rem 0.3rem;
      font-size: 0.79rem;
    }

    &.btn-lg {
      padding: 0.35rem .9rem;
      font-size: 1.075rem;
    }
  }
}

.save-and-preview {
  text-align: right;
  margin-bottom: 4rem;
}

.application-preview {
  background-color: $white;

  .form-control:disabled,
  .form-control[readonly] {
    background-color: $white;
  }

  .custom-control-input[disabled]~.custom-control-label,
  .custom-control-input:disabled~.custom-control-label {
    color: $black;
  }

  .custom-control-input[disabled]~.custom-control-label::before,
  .custom-control-input:disabled~.custom-control-label::before {
    background-color: $white;
  }

  @media print {

    .custom-radio .custom-control-input:disabled:checked~.custom-control-label::before,
    .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
      background-color: $white;
    }

    .custom-checkbox .custom-control-input:checked~.custom-control-label::after{
      content: '✘';
      font-size: 1rem;
      top: 0.0rem;
      left: -1.4rem;
    }

    .custom-radio .custom-control-input:checked~.custom-control-label::after {
      content: '✘';
      font-size: 1rem;
      top: 0.0325rem;
      left: -1.35rem;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      background: none;
    }

    .print-application-button {
      display: none;
    }
  }

  .upload-hint {
    display: none;
  }

  #div_id_schedule_upload,
  #div_id_finance_upload,
  #div_id_creative_upload {
    .input-group-prepend {
      display: none;
    }

    .input-group.mb-0 {
      display: none;
    }

    .custom-control.custom-checkbox {
      display: none;
    }
  }

  .form-control.form-control-readonly {
    display: block;
    height: auto;
    min-height: 40px;
  }
}

@media print {
  html {
    zoom: 0.8;
  }

  .close {
    display: none !important;
  }

  .modal-fullscreen {
    -ms-overflow-style: none; // Internet Explorer 10+
    scrollbar-width: none; // Firefox
  }

  .modal-fullscreen::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  .form-group {
    page-break-inside: avoid;
  }

  .row.no-page-break {
    display: block !important;
  }

  .form-row.form-row-break {
    display: block !important;
  }

  @page {

    size: portrait;
    margin: 10mm 10mm;
  }
}

.rating-frame-wrapper {
  position: sticky;
  top: 1rem;
}

.rating-frame {
  border: 2px solid $gray-700;
  height: calc(100vh - 4rem);
  border-top-width: 2px;
  top: 2rem;
  left: 0;
  z-index: 1;
  width: 100%;
  position: absolute;
}

.rating-hint {
  font-size: 1.25rem;
}

.rating-category {
  label:not(.requiredField) {
    cursor: pointer;
  }

  label.requiredField {
    font-weight: bold;
  }

  .asteriskField {
    padding: 0.25rem;
  }
}

.currentRating {
  padding-bottom: 0.25rem;
}

.ranking-info {
  font-size: 0.9rem;
  line-height: 1.33;
  position: relative;
}

.ranking-action {
  border-bottom: 1px solid $gray-300;
  background: $gray-100;
}

.feedback-box {
  margin-left: -$list-group-item-padding-x;
  padding-left: $list-group-item-padding-x;
  margin-right: -$list-group-item-padding-x;
  padding-right: $list-group-item-padding-x;
}

.feedback-toogle-all {
  position: absolute;
  bottom: -1.75rem;
  right: 1rem;
}
