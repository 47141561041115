.program__nav-tabs {
  border-bottom: 2px solid $secondary;

  .nav-link {
    border: 2px solid transparent;
  }

  .nav-item {
    margin-bottom: -2px;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: $secondary $secondary #fff;
  }
}

.program__entry {
  padding-top: 1rem;
  border-bottom: 2px dashed $secondary;
}

.program__block {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
