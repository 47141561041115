.lead {
  font-family: $font-family-sans-serif;
}

.section-100 {
  min-height: calc(100vh - 38px - 3.5rem - 50px);
}


.socialaccount_provider {
  font-family: $font-family-sans-serif;
}

.socialaccount_provider {
  .icon {
    padding-right: .5rem;
  }
}

label {
  font-family: $font-family-sans-serif;
}

.timesince {
  font-size: 70%;
  font-family: $font-family-sans-serif;
}

.text-count {
  font-size: 70%;
  font-family: $font-family-sans-serif;
  float: right;
  margin-left: 3rem;
}

.application__form {
  select[name*="date_of_birth"] {
    width: auto;
    display: inline-block;
    margin-right: 4px;
  }
}
