.text__layout--left {
  text-align: left;
}

.text__layout--center {
  text-align: center;
}

.text__layout--columns {
  text-align: justify;

  @include media-breakpoint-up(md) {
    columns: 2;
    column-gap: 2rem;
  }
  @include media-breakpoint-up(lg) {
    columns: 3;
    column-gap: 3rem;
  }
}
