.tw-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.tw-col-span-11 {
  grid-column: span 11 / span 11;
}

.tw-col-span-1 {
  grid-column: span 1 / span 1;
}

.tw-col-span-12 {
  grid-column: span 12 / span 12;
}

.tw-my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tw-mb-8 {
  margin-bottom: 2rem;
}

.tw-ml-2 {
  margin-left: 0.5rem;
}

.tw-mt-1 {
  margin-top: 0.25rem;
}

.tw-mt-8 {
  margin-top: 2rem;
}

.tw-mb-4 {
  margin-bottom: 1rem;
}

.tw-block {
  display: block;
}

.tw-flex {
  display: flex;
}

.tw-grid {
  display: grid;
}

.tw-h-8 {
  height: 2rem;
}

.tw-h-5 {
  height: 1.25rem;
}

.tw-h-auto {
  height: auto;
}

.tw-h-4 {
  height: 1rem;
}

.tw-w-6 {
  width: 1.5rem;
}

.tw-w-5 {
  width: 1.25rem;
}

.tw-w-full {
  width: 100%;
}

.tw-w-4 {
  width: 1rem;
}

.tw-max-w-full {
  max-width: 100%;
}

.tw-break-inside-avoid-column {
  break-inside: avoid-column;
}

.tw-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.tw-items-center {
  align-items: center;
}

.tw-gap-8 {
  gap: 2rem;
}

.tw-gap-y-6 {
  row-gap: 1.5rem;
}

.tw-gap-x-4 {
  column-gap: 1rem;
}

.tw-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.tw-break-words {
  overflow-wrap: break-word;
}

.tw-border {
  border-width: 1px;
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.tw-object-contain {
  object-fit: contain;
}

.tw-object-center {
  object-position: center;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.tw-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-font-medium {
  font-weight: 500;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.tw-text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.tw-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

[x-cloak] {
  display: none;
}

@media (min-width: 768px) {

  .md\:tw-columns-2 {
    columns: 2;
  }
}

@media (min-width: 1024px) {

  .lg\:tw-columns-3 {
    columns: 3;
  }
}
