.modal{
  &.modal-fullscreen{
    .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      max-width: none;
    }

    .modal-content {
      height: auto;
      min-height: 100%;
      border-radius: 0;
      border: none;
    }
  }
}