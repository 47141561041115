$caption-bg-normal: transparentize(lighten($gray-900, 15%), 0.4);
$caption-bg-darker: transparentize($gray-900, 0.25);
$caption-bg-highlight: transparentize(darken($gray-900, 10%), 0.2);

.container-gallery {
  margin-bottom: 2.5rem;
}

.gallery-items {
  position: relative;
}

.gallery-image {
  position: relative;
}

.caption {
  // prefixed to avoid legacy collision
  background: $caption-bg-normal;
  transition: visibility 0.5s linear 0s, opacity .15s linear, background .15s linear;
  bottom: 0;
  color: $white;
  font-size: 0.7em;
  height: auto;
  padding: 0.5em;
  position: absolute;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.35);
  width: 100%;
  z-index: 1;

  @include media-breakpoint-up(md) {
    font-size: 0.75em;
  }

  @include media-breakpoint-up(lg) {
    font-size: 0.85em;
    padding: 0.75em;
  }

  @include media-breakpoint-down(md) {
    text-align: left;
  }

  @include media-breakpoint-down(md) {
    background: $caption-bg-highlight;
    position: relative;
    height: 100%;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      background: $caption-bg-highlight;
    }
  }
}

.caption.caption--darker {
  // prefixed to avoid legacy collision
  background: $caption-bg-darker;

  @include media-breakpoint-down(md) {
    background: $caption-bg-highlight;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      background: $caption-bg-highlight;
    }
  }
}

.image-rights,
.caption__rights {
  text-align: right;
}

.caption__rights {
  margin-top: 0.33em;
  font-size: 0.90em;
  margin-bottom: -0.33em;
}


.caption.caption--show-hover {
  display: none;
}

.caption.caption--clickable {
  cursor: pointer;
}

@include media-breakpoint-up(md) {
  .caption.caption--show-hover {
    opacity: 0;
    display: block;
  }

  .gallery-image:hover {
    .caption.caption--show-hover {
      visibility: visible;
      opacity: 1.0;
    }
  }
}

.caption--bottom-spaced {
  @include media-breakpoint-up(md) {
    padding-bottom: 30px;
  }
}

.caption__title,
.caption__text,
.caption__link {
  color: $white;
}

.caption__link:hover,
.caption__link:focus {
  color: $white;
  text-decoration: underline;
}

.caption__title {
  font-size: 100%;
  margin-top: 0;

  @include media-breakpoint-up(md) {
    font-size: 120%;
  }
}

.caption__text {
  margin-bottom: 0;
}
