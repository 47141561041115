.listing__label {
  display: block;
  font-weight: bold;
  font-size: 120%;
}

.listing__description {}

.listing__block {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
